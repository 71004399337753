import React, { useState } from 'react';

const Step3 = ({ nextStep, prevStep, handleChange, formData }) => {
  const [error, setError] = useState('');
  const [showSecondaryPhone, setShowSecondaryPhone] = useState(formData.secondaryPhoneOption === 'Yes');
  const [showTelegram, setShowTelegram] = useState(formData.telegramnumberoption === 'Yes');

  // Validation function to check if all required fields are filled
  const validateForm = () => {
    if (
      !formData.mobilePhone ||
      (showSecondaryPhone && !formData.secondaryPhone) ||
      !formData.whatsappNumber ||
      (showTelegram && !formData.telegramnumber) ||
      !formData.email ||
      (formData.instagramOption === 'Yes' && !formData.instagramHandle) ||
      (formData.facebookOption === 'Yes' && !formData.facebookHandle)
    ) {
      setError('Please complete all required fields before proceeding.');
      return false;
    }
    setError('');
    return true;
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: document.querySelector('.form-container').offsetTop,
      behavior: 'smooth',
    });
  };

  const handleNext = () => {
    if (validateForm()) {
      nextStep();
    } else {
      scrollToTop();
    }
  };

  return (
    <div style={{ paddingTop: '40px', paddingBottom: '40px' }}>
      <h2>Contact Information</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}

      <label>Mobile Phone</label>
      <input
        type="text"
        value={formData.mobilePhone}
        onChange={handleChange('mobilePhone')}
        required
      />

      <label>Secondary Phone or Landline</label>
      <select
        value={formData.secondaryPhoneOption}
        onChange={(e) => {
          handleChange('secondaryPhoneOption')(e);
          setShowSecondaryPhone(e.target.value === 'Yes');
        }}
      >
        <option value="No">No</option>
        <option value="Yes">Yes</option>
      </select>
      {showSecondaryPhone && (
        <input
          type="text"
          value={formData.secondaryPhone}
          onChange={handleChange('secondaryPhone')}
          placeholder="Enter secondary phone"
          required
        />
      )}

      <label>WhatsApp Number</label>
      <input
        type="text"
        value={formData.whatsappNumber}
        onChange={handleChange('whatsappNumber')}
        required
      />

      <label>Telegram Number</label>
      <select
        value={formData.telegramnumberoption}
        onChange={(e) => {
          handleChange('telegramnumberoption')(e);
          setShowTelegram(e.target.value === 'Yes');
        }}
      >
        <option value="No">No</option>
        <option value="Yes">Yes</option>
      </select>
      {showTelegram && (
        <input
          type="text"
          value={formData.telegramnumber}
          onChange={handleChange('telegramnumber')}
          placeholder="Enter telegram number"
          required
        />
      )}

      <label>Email</label>
      <input
        type="text"
        value={formData.email}
        onChange={handleChange('email')}
        required
      />

      <label>Instagram</label>
      <select
        value={formData.instagramOption}
        onChange={(e) => {
          handleChange('instagramOption')(e);
        }}
      >
        <option value="No">No</option>
        <option value="Yes">Yes</option>
      </select>
      {formData.instagramOption === 'Yes' && (
        <input
          type="text"
          value={formData.instagramHandle}
          onChange={handleChange('instagramHandle')}
          placeholder="@yourusername"
          required
        />
      )}

      <label>Facebook</label>
      <select
        value={formData.facebookOption}
        onChange={(e) => {
          handleChange('facebookOption')(e);
        }}
      >
        <option value="No">No</option>
        <option value="Yes">Yes</option>
      </select>
      {formData.facebookOption === 'Yes' && (
        <input
          type="text"
          value={formData.facebookHandle}
          onChange={handleChange('facebookHandle')}
          placeholder="https://facebook.com/yourusername"
          required
        />
      )}

      <button className='formprev' onClick={prevStep}>Back</button>
      <button className='formnext' onClick={handleNext}>Next</button>
    </div>
  );
};

export default Step3;
