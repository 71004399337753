import React from 'react';
import './staticpages.css';

const Privacy = () => {
  return (
    <div className='privacy-page'>
      <div className='privacy-container'>
        <h1>Privacy Policy</h1>
        <div className='privacy-content'>
          <p><strong>Introduction</strong></p>
          <p>
            The Miss Universe Organization ("Company" or "We") respect your privacy and are committed to protecting it through our compliance with this policy.
          </p>
          <p>
            This policy describes the types of information we may collect from you or that you may provide when you visit the website 
            <a href="https://www.missuniverseromania.ro/" target="_blank" rel="noopener noreferrer"> https://www.missuniverseromania.ro/</a> 
            (our "Website") and our practices for collecting, using, maintaining, protecting, and disclosing that information.
          </p>
          
          <p><strong>This policy applies to information we collect:</strong></p>
          <ul>
            <li>On this Website.</li>
            <li>In email, text, and other electronic messages between you and this Website.</li>
            <li>When you interact with our advertising and applications on third-party websites and services, if those applications or advertising include links to this policy.</li>
          </ul>
          
          <p><strong>It does not apply to information collected by:</strong></p>
          <ul>
            <li>Us offline or through any other means, including on any other website operated by Company or any third party.</li>
            <li>Any third party, including through any application or content (including advertising) that may link to or be accessible from or through the Website.</li>
          </ul>

          <h2>Children Under the Age of 16</h2>
          <p>
            Our Website is not intended for children under 16 years of age. No one under age 16 may provide any personal information on the Website. We do not knowingly collect personal information from children under 16.
          </p>

          <h2>Information We Collect About You and How We Collect It</h2>
          <p>
            We collect several types of information from and about users of our Website, including information:
          </p>
          <ul>
            <li>By which you may be personally identified, such as name, postal address, email address, telephone number, or any other identifier by which you may be contacted online or offline ("personal information").</li>
            <li>That is about you but individually does not identify you, such as cookie information and website analytics.</li>
            <li>About your internet connection, the equipment you use to access our Website, and usage details.</li>
          </ul>

          <h2>How We Use Your Information</h2>
          <p>
            We use information that we collect about you or that you provide to us, including any personal information, to:
          </p>
          <ul>
            <li>Present our Website and its contents to you.</li>
            <li>Provide you with information, products, or services that you request from us.</li>
            <li>Fulfill any other purpose for which you provide it.</li>
            <li>Provide you with notices about your e-mail subscription.</li>
            <li>Notify you about changes to our Website or any products or services we offer or provide through it.</li>
            <li>Allow you to participate in interactive features on our Website.</li>
            <li>For any other purpose with your consent.</li>
          </ul>

          <h2>Disclosure of Your Information</h2>
          <p>
            We may disclose aggregated information about our users, and information that does not identify any individual. We may disclose personal information that we collect or you provide as described in this privacy policy to our subsidiaries and service providers.
          </p>

          <h2>Changes to Our Privacy Policy</h2>
          <p>
            If we make material changes to how we treat our users' personal information, we will notify you by email to the email address specified in your account or through a notice on the Website home page. The date the privacy policy was last revised is identified at the top of the page.
          </p>

          {/* Add the remaining content following a similar structure */}
          
        </div>
      </div>
    </div>
  );
}

export default Privacy;
