import React from 'react';
import './Social.css';

const Social = () => (
  <div className="social">
    <div className='content' >
      
      <center><svg preserveAspectRatio="xMidYMid meet" data-bbox="0.96 0.437 62.08 59.239" xmlns="http://www.w3.org/2000/svg" viewBox="0.96 0.437 62.08 59.239" height="60" width="64" data-type="color" role="presentation" aria-hidden="true">
    <g>
        <path fill="#ffffff" d="m63.04 30.057-26.18-2.383 10.074-11.7-.022-.02-12.261 9.655L32.111.437h-.045l-2.54 25.13-12.262-9.635-.044.021 10.14 11.7-26.4 2.404v.042l26.378 2.424-10.14 11.721.022.043 12.306-9.719 2.54 25.108h.044l2.541-25.023 12.24 9.634h.021L36.75 32.523 63.04 30.1v-.042Z" data-color="1"></path>
    </g>
  </svg></center>
 
    
      <h2 style={{paddingTop:'20px'}}>Don't Miss a Beat!</h2>
      <h3> Follow our social media channels to stay up to date as the competition unfolds</h3>
    </div>
    SOCIAL FEED
    <div className='social-icons'>
      <div className='col-xl-6' style={{alignContent: 'center'}}>
        <h1 style={{color: '#B49B57'}}>FOLLOW US ON <br />
        SOCIAL MEDIA</h1>
        
      </div>
      <div className='col-xl-6' style={{alignContent: 'center'}}>
      <table>
  <tr>
    <td>
      <a href='https://www.facebook.com/missromaniauniverse' target='_blank' rel='noopener noreferrer'>
        <img src='/img/fb.png' alt='Facebook' />
      </a>
    </td>
    <td>
      <a href='https://www.instagram.com/missuniverseromania/' target='_blank' rel='noopener noreferrer'>
      <img src='/img/insta.png' alt='Instagram' />
      </a>
    </td>
    
  </tr>
  <tr>
    <td>
      <a href='https://www.youtube.com/missuniverse' target='_blank' rel='noopener noreferrer'>
      <img src='/img/yt.png' alt='Youtube' />
      </a>
    </td>
    <td>
      <a href='https://www.tiktok.com/@missromaniauniverse' target='_blank' rel='noopener noreferrer'>
      <img src='/img/tiktok.png' alt='Tiktok' />
      </a>
    </td>
    
  </tr>
</table>

      </div>
    </div>
  </div>
);

export default Social;
