import React from 'react';
import './staticpages.css';

const Terms = () => {
  return (
    <div className='terms-page'>
      <div className='terms-container'>
        <h1>Terms of Use</h1>
        <div className='terms-content'>
          <p>ACCESS TO AND USE OF THIS WEBSITE IS PROVIDED BY JKN UNIVERSE, LLC SUBJECT TO THE FOLLOWING TERMS AND CONDITIONS.</p>
          
          <h2>1. Acceptance</h2>
          <p>
          www.missuniverseromania.ro/ (the "Website") is a website operated by JKN UNIVERSE LLC ("JKN LLC", "we", "us", "our"). JKN UNIVERSE LLC is a limited liability company based in New York at 1370 Avenue of the Americas, 16th Floor New York, NY 10019.
          </p>
          <p>
            By visiting the Website you agree, without limitation or qualification, to be bound by these terms and conditions of use (the “Terms of Use”), the JKN LLC Privacy Policy (the “Privacy Policy”) and the JKN LLC Cookies Policy (the "Cookies Policy") incorporated herein by reference.
          </p>
          <p>
            Where permitted by local law, JKN LLC reserves the right to modify or revise the Terms of Use, in its sole discretion, at any time. By visiting and using the Website you agree to be bound by any and all subsequent modifications or revisions and to comply with any applicable laws and regulations. Any modifications or revisions we may make to the Terms of Use will be posted on this page. JKN LLC recommends that Website users review the most up-to-date version of the Terms of Use periodically. If you do not agree or consent to these modifications or revisions, do not continue to use the Website.
          </p>
          <p>
            We do not guarantee that the Website or the Contents (as described in more detail below), will always be available or be uninterrupted. We may suspend, withdraw, discontinue or change all or any part of the Website without notice. To the extent allowed by law, we will not be liable to you if for any reason the Website is unavailable at any time or for any period. You are responsible for making all arrangements necessary for you to have access to the Website. You are also responsible for ensuring that all persons who access the Website through your internet connection or using your device are aware of these Terms of Use and other applicable terms and conditions, and that they comply with them.
          </p>
          <h2>2. Personal Information</h2>
          <p>
            The personal information you submit to JKN LLC and that JKN LLC collects from you is governed by the JKN LLC Privacy Policy and Cookies Policy. To the extent there is an inconsistency between these Terms of Use and the Privacy Policy and/or Cookies Policy, the Privacy Policy and/or Cookies Policy as applicable shall govern. You are responsible for providing information that is accurate, current, and complete.
          </p>
          <p>
            If you provide information that is not accurate, current, or complete, or if JKN LLC has reason to believe that information you provided is not accurate, current, or complete, JKN LLC has the right to prohibit you from any and all future use of the Website.
          </p>

          <h2>3. Use Restrictions</h2>
          <p>
            The content on the Website, such as text, graphics, source code, images, photographs, illustrations, trademarks, trade names, service marks, logos, information obtained from JKN LLC licensors, and other materials (the “Contents”) is protected by intellectual property rights under both United States and foreign laws. Ownership of the Contents - in other words, ownership of all intellectual property rights in such Contents - remains with JKN LLC or our licensors. All rights not expressly granted herein are reserved to JKN LLC and its licensors. If you violate any of these Terms of Use, your permission to use the Contents automatically terminates and you must immediately destroy any copies you have made of any portion of the Contents.
          </p>
          <p>
            Any use of the Contents not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.
          </p>

          <h2>4. Submission of Ideas</h2>
          <p>
            JKN LLC does not accept submissions of any kind through the Website (e.g., materials, ideas, suggestions, know-how, or concepts, other than those expressly sought through permitted use of the Website) or otherwise. All submissions, other than those expressly sought through permitted use of the Website, may be deleted unread or destroyed, at JKN LLC’s discretion. You hereby grant to JKN LLC an irrevocable, perpetual, royalty-free licence (including the right to sublicense to third parties) to exercise the intellectual property rights in any submissions made by you or on your behalf in connection with the Website ("Licensed Submissions").
          </p>

          <h2>5. Hyperlinks from the Website</h2>
          <p>
            The Website may contain hyperlinks to other websites and webpages (“Third-Party Pages”), as well as to text, graphics, videos, images, music, sounds, applications and information belonging to or originating from other third-parties (collectively, “Third-Party Applications”). JKN LLC does not investigate, monitor, or review any Third-Party Pages or Third-Party Applications to ensure their accuracy, completeness, or appropriateness.
          </p>

          <h2>6. No Reliance on Information</h2>
          <p>
            The material and Contents on the Website are provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on the Website.
          </p>

          {/* Add the remaining sections in a similar structure */}
          
        </div>
      </div>
    </div>
  );
}

export default Terms;
