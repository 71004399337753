import React, { useState } from 'react';

const Step4 = ({ prevStep, nextStep, handleChange, formData }) => {
  const [error, setError] = useState('');

  // Validation function to check if all required fields are filled
  const validateForm = () => {
    if (
      !formData.height ||
      !formData.weight ||
      !formData.bustwaisthips ||
      !formData.eyeColor ||
      !formData.hairColor
    ) {
      setError('Please complete all fields before proceeding.');
      return false;
    }
    setError('');
    return true;
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: document.querySelector('.form-container').offsetTop,
      behavior: 'smooth',
    });
  };

  const handleNext = () => {
    if (validateForm()) {
      nextStep();
    } else {
      scrollToTop();
    }
  };

  return (
    <div style={{ paddingTop: '40px', paddingBottom: '40px' }}>
      <h2>Physical Characteristics</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}

      <label>Height (cm)</label>
      <input
        type="text"
        value={formData.height}
        onChange={handleChange('height')}
        required
      />

      <label>Weight (kg)</label>
      <input
        type="text"
        value={formData.weight}
        onChange={handleChange('weight')}
        required
      />

      <label>Bust - Waist - Hips (cm)</label>
      <input
        type="text"
        value={formData.bustwaisthips}
        onChange={handleChange('bustwaisthips')}
        required
      />

      <label>Eye Color</label>
      <input
        type="text"
        value={formData.eyeColor}
        onChange={handleChange('eyeColor')}
        required
      />

      <label>Hair Color</label>
      <input
        type="text"
        value={formData.hairColor}
        onChange={handleChange('hairColor')}
        required
      />

      <button className='formprev' onClick={prevStep}>Back</button>
      <button className='formnext' onClick={handleNext}>Next</button> {/* Updated button */}
    </div>
  );
};

export default Step4;
