import React from 'react';

const Step7 = ({ confirmationMessage, confirmationError, prevStep }) => {
  return (
    <div className="confirmation-step" style={{paddingTop: '40px', paddingBottom: '40px'}}>
      <h2>Confirmation</h2>
      <p>{confirmationMessage}</p>
      {confirmationError === 'Yes' && (
        <button className='formprev' onClick={prevStep}>Back</button>
        )}
    </div>
   
  );
};

export default Step7;
