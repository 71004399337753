import React from 'react';
import './staticpages.css';

const Cookie = () => {
  return (
    <div className='cookie-page'>
      <div className='cookie-container'>
        <h1>Cookie Policy</h1>
        <div className='cookie-content'>
          <p>
            JKN UNIVERSE, LLC ("we", "us", "our") are committed to protecting and respecting your privacy as described in our Privacy Notice, of which this Cookie Policy forms a part.
          </p>
          <p>
            By consenting to this cookie policy ("Cookie Policy"), you acknowledge and consent to our website using cookies and other similar technologies (as described below) to distinguish you from other users of our website and for analytics. This helps us to provide you with a good experience during your visit to our website. This also allows us to improve our website and provide you with tailored content and advertising.
          </p>
          <p>
            A cookie is a small file of letters and numbers that we store via your browser on the hard drive of your computer or your mobile device, if you agree.
          </p>
          <p>
            We may automatically collect and store certain information about your interaction with our website, including IP addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamps, and related data.
          </p>

          <h2>Purposes of Collection</h2>
          <p>
            You can find more information about the types of cookies we use and the purposes for which we use them set out below.
          </p>

          <h2>First Party Cookies</h2>
          <ul>
            <li>
              <strong>Session Cookies (Technical Cookies):</strong> Their use is strictly limited to sending the session identifiers (random numbers generated by the server) needed for secure and efficient website browsing. These cookies are deleted upon browser closing.
            </li>
            <li>
              <strong>Technical Cookie:</strong> Required to keep track of the consent given by the user.
            </li>
          </ul>
          
          <h2>Third Party Cookies</h2>
          <p>
            Some third parties can also provide cookies on our website(s). These cookies or similar technologies are managed by third parties and are divided into the following categories:
          </p>
          <ul>
            <li>
              <strong>Analytical Cookies:</strong> We use Google Analytics to analyze how our website is used. These cookies generate information on your use of our website and are collected from Google in an anonymous form.
            </li>
            <li>
              <strong>Marketing/Advertising Cookies:</strong> Used to provide advertising services. Disabling these cookies does not affect website use.
            </li>
            <li>
              <strong>Profiling Cookies:</strong> Used to trace user browsing and create profiles about their preferences and behavior.
            </li>
          </ul>

          <h2>Refusing or Deleting Cookies</h2>
          <p>
            Most internet browsers are set up by default to accept cookies. However, if you want to refuse or delete any cookies, please refer to the help and support area on your internet browser for instructions.
          </p>

          <h2>Changes to our Cookie Policy</h2>
          <p>
            This Cookie Policy may be updated from time to time. If we change anything important about this Cookie Policy, we will notify you through a pop-up on the website for a reasonable length of time prior to and following the change.
          </p>

          {/* Add the remaining sections following a similar structure */}
          
        </div>
      </div>
    </div>
  );
}

export default Cookie;
