import React, { useState } from 'react';

const Step6 = ({ prevStep, handleSubmit, setFormData, handleChange, formData }) => {
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // New state to handle submit status

  const handleFileChange = (input) => (e) => {
    const file = e.target.files[0];
    setFormData((prevState) => ({
      ...prevState,
      [input]: file,
    }));
  };

  // Validation function to ensure all photos are uploaded
  const validateForm = () => {
    if (!formData.photo1 || !formData.photo2 || !formData.photo3 || !formData.photo4) {
      setError('Please upload all photos before submitting.');
      return false;
    }
    setError('');
    return true;
  };

  const handleFinalSubmit = () => {
    if (validateForm()) {
      setIsSubmitting(true); // Show loading icon and disable button
      handleSubmit(); // Submit the form
    }
  };

  return (
    <div style={{ paddingTop: '40px', paddingBottom: '40px' }}>
      <h2>Upload photos</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}

      <label>Upload photo 1</label>
      <input
        type="file"
        onChange={handleFileChange('photo1')}
        accept="image/*"
        required
      />

      <label>Upload photo 2</label>
      <input
        type="file"
        onChange={handleFileChange('photo2')}
        accept="image/*"
        required
      />

      <label>Upload photo 3</label>
      <input
        type="file"
        onChange={handleFileChange('photo3')}
        accept="image/*"
        required
      />

      <label>Upload photo 4</label>
      <input
        type="file"
        onChange={handleFileChange('photo4')}
        accept="image/*"
        required
      />
      <p>
      *By clicking submit, I understand that untrue information sent may lead to disqualification from the competition.
      </p>

      <button className='formprev' onClick={prevStep} disabled={isSubmitting}>
        Back
      </button>

      {!isSubmitting ? (
        <button className='formnext' onClick={handleFinalSubmit}>
          Submit
        </button>
      ) : (
        <div className="loading-icon">
          <img src="/img/loading.gif" alt="Loading..." /> {/* Replace with your loading spinner */}
        </div>
      )}
    </div>
  );
};

export default Step6;
