import React from 'react';
import Slider from '../components/Slider.js';
import About from './About.js';
import Social from '../components/Social.js';
import Sponsors from '../components/Sponsors.js';
const slides = [
  {
    title: "",
    text: "",
    image: '',
    buttonText: "Submit Application",
    backgroundImage: '/img/slider3.jpg',
  },
  {
    title: "Get your hands on Exclusive Miss Universe Merchandise. At your doorstep.",
    text: "",
    image: '/img/sliderimage1.png',
    buttonText: "Explore the Shop",
    backgroundImage: '/img/slider1.jpg',
  },
  {
    title: "Sheynnis Palacios is Miss Universe 2023",
    text: "",
    image: '',
    buttonText: "Learn More",
    backgroundImage: '/img/slider2.jpg',
  },
  // Add more slides as needed
];
const Home = () => (
  <div>
    <div className="App">
      <Slider slides={slides} />
    </div>
    <Social />
    <About />
    <Sponsors />

  </div>
);

export default Home;
