import React from 'react';
import './staticpages.css';

const Contact = () => {
  return (
    <div className='contact-page'>
      <div className='contact-container'>
        <h1>Contact Us</h1>
        <p>If you have any questions or concerns, feel free to reach out!</p>
        <div className='contact-details'>
          <div className='contact-card'>
            <h3>Email Us</h3>
            <p><a href='mailto:info@yourwebsite.com'>info@yourwebsite.com</a></p>
          </div>
          <div className='contact-card'>
            <h3>Call Us</h3>
            <p>+123 456 7890</p>
          </div>
          <div className='contact-card'>
            <h3>Visit Us</h3>
            <p>123 Your Street, City, Country</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
