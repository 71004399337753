import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  // State to manage menu toggle
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle function to open/close the menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header>
      
      <div className="content">
        <div className="logo">
          <Link to="/">
            <img src="/img/misslogo.png" alt="Miss Universe Logo" />
          </Link>
        </div>

        {/* Menu toggle button (for mobile) */}
        <button className="menu-toggle" onClick={toggleMenu}>☰</button>

        {/* Navigation for desktop */}
        <nav className="desktop-nav">
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/form">Application</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </nav>
      </div>

      {/* Right-side drawer navigation (for mobile) */}
      <nav className={`drawer ${isMenuOpen ? 'open' : ''}`}>
        <ul>
          <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
          <li><Link to="/about" onClick={toggleMenu}>About</Link></li>
          <li><Link to="/form" onClick={toggleMenu}>Form</Link></li>
          <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
        </ul>
      </nav>

      {/* Overlay for the drawer */}
      {isMenuOpen && <div className="overlay" onClick={toggleMenu}></div>}
    </header>
  );
};

export default Header;
