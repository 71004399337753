import React from 'react';

const Step1 = ({ nextStep, handleChange, formData }) => {
  return (
    <div style={{paddingTop: '40px', paddingBottom: '40px'}}>
      
      <h2>Miss Universe Romania participation rules</h2>
      <br/><br></br>

      <p>Any woman can apply for the Miss Universe Romania casting if:</p>
<p>- She is recognized medically and legally as a female in Romania.</p>
<p>- She is a mother, pregnant, married, divorced, or single woman.</p>
<p>- She is at least 18 years of age or older on the Miss Universe Romania 2024 Final Show date. Yes, starting with 2024, Miss Universe dropped the upper age limit, meaning every adult woman in the world is now eligible to compete for Miss Universe.</p>
<p>- She has competed previously or not in another Miss Universe national competition, but she has never participated in Miss Universe.</p>
<p>- She is a citizen of Romania.</p>
<p>- She meets the eligibility criteria, regardless of her height or weight.</p>
<p>All women wishing to participate in MISS UNIVERSE Romania must fill out the online casting registration form. There is no other way to register (by email, post, etc.).</p>


<br/><br/>
<p>By clicking the 'ACCEPT' button, you confirm that you have read and understood the participation rules for Miss Universe Romania.</p>
      <button className='formnext' onClick={nextStep}>Accept</button>
    </div>
  );
};

export default Step1;
