import React, { useState } from 'react';

const Step5 = ({ prevStep, nextStep, handleChange, formData }) => {
  const [error, setError] = useState('');

  // Validation function to check if all required fields are filled
  const validateForm = () => {
    if (
      !formData.plasticsurgeries ||
      !formData.tattoos ||
      !formData.languages ||
      !formData.hobbies ||
      !formData.talents ||
      !formData.musicgenre ||
      !formData.singers ||
      !formData.moviegenre ||
      !formData.books ||
      !formData.people ||
      !formData.occupation ||
      !formData.contract ||
      !formData.activities ||
      !formData.pageants ||
      !formData.future ||
      !formData.topless ||
      !formData.criminal ||
      !formData.participate ||
      !formData.qualities ||
      !formData.details
    ) {
      setError('Please complete all required fields before proceeding.');
      return false;
    }
    setError('');
    return true;
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: document.querySelector('.form-container').offsetTop,
      behavior: 'smooth',
    });
  };

  const handleNext = () => {
    if (validateForm()) {
      nextStep();
    } else {
      scrollToTop();
    }
  };

  return (
    <div style={{ paddingTop: '40px', paddingBottom: '40px' }}>
      <h2>Questions</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}

      <label>Do you have plastic surgeries? If no, please write NO, if yes, please mention every one of them in detail.</label>
      <input
        type="text"
        value={formData.plasticsurgeries}
        onChange={handleChange('plasticsurgeries')}
        required
      />

      <label>Do you have tattoos? If no, please write NO, if yes, please mention every one of them in detail.</label>
      <input
        type="text"
        value={formData.tattoos}
        onChange={handleChange('tattoos')}
        required
      />

      <label>What foreign languages do you speak? Please specify the level: Beginner, Intermediate or Advanced.</label>
      <input
        type="text"
        value={formData.languages}
        onChange={handleChange('languages')}
        required
      />

      <label>What is your relationship status?</label>
      <select value={formData.relationshipstatus} onChange={handleChange('relationshipstatus')}>
        <option value="Single">Single</option>
        <option value="In relationship">In relationship</option>
        <option value="Never been married">Never been married</option>
        <option value="Married">Married</option>
        <option value="Divorced">Divorced</option>
      </select>

      <label>Are you a mother?</label>
      <select value={formData.mother} onChange={handleChange('mother')}>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
        <option value="I was">I was</option>
        <option value="I am pregnant">I am pregnant</option>
      </select>

      <label>What are your hobbies?</label>
      <input
        type="text"
        value={formData.hobbies}
        onChange={handleChange('hobbies')}
        required
      />

      <label>Do you have any talents like singing, dancing, acting, etc? If no, please write NO, if yes, please write everything in detail.</label>
      <input
        type="text"
        value={formData.talents}
        onChange={handleChange('talents')}
        required
      />

      <label>What type of music genre do you like?</label>
      <input
        type="text"
        value={formData.musicgenre}
        onChange={handleChange('musicgenre')}
        required
      />

      <label>Write the names of the singers or bands which you listen frequently.</label>
      <input
        type="text"
        value={formData.singers}
        onChange={handleChange('singers')}
        required
      />

      <label>What is your favorite movie genre?</label>
      <input
        type="text"
        value={formData.moviegenre}
        onChange={handleChange('moviegenre')}
        required
      />

      <label>The last 3 books you have read.</label>
      <input
        type="text"
        value={formData.books}
        onChange={handleChange('books')}
        required
      />

      <label>What type of people and human qualities you like and which ones you dislike?</label>
      <input
        type="text"
        value={formData.people}
        onChange={handleChange('people')}
        required
      />

      <label>What’s your occupation?</label>
      <input
        type="text"
        value={formData.occupation}
        onChange={handleChange('occupation')}
        required
      />

      <label>Do you have any management or modeling contract signed with a company? If no, please write NO, if yes, please write the name and contact details of your agent, contract details, and when your contract will expire.</label>
      <input
        type="text"
        value={formData.contract}
        onChange={handleChange('contract')}
        required
      />

      <label>Do you have important activities as a model? If no, please write NO, if yes, please write everything in detail.</label>
      <input
        type="text"
        value={formData.activities}
        onChange={handleChange('activities')}
        required
      />

      <label>Did you participate in other national or international beauty pageants in the past? If no, please write NO, if yes, please write every participation in detail.</label>
      <input
        type="text"
        value={formData.pageants}
        onChange={handleChange('pageants')}
        required
      />

      <label>What are your plans in the next 5-10 years? Have you ever been involved in any media scandal? If no, please write NO, if yes, please write everything in detail.</label>
      <input
        type="text"
        value={formData.future}
        onChange={handleChange('future')}
        required
      />

      <label>Have you ever appeared topless or completely naked in any printed or online publications, in any photo or video materials? If no, please write NO, if yes, please write everything in detail and write the links of the materials if they are published online.</label>
      <input
        type="text"
        value={formData.topless}
        onChange={handleChange('topless')}
        required
      />

      <label>Do you have a criminal record or have you ever been arrested/convicted? If no, please write NO, if yes, please write everything in detail.</label>
      <input
        type="text"
        value={formData.criminal}
        onChange={handleChange('criminal')}
        required
      />

      <label>Why do you wish to participate in Miss Universe Romania?</label>
      <input
        type="text"
        value={formData.participate}
        onChange={handleChange('participate')}
        required
      />

      <label>What are your qualities due to which you consider that you are suitable to represent your country in MISS UNIVERSE?</label>
      <input
        type="text"
        value={formData.qualities}
        onChange={handleChange('qualities')}
        required
      />

      <label>Other details about you that you would like us to know or any other message addressed to our selection committee?</label>
      <input
        type="text"
        value={formData.details}
        onChange={handleChange('details')}
        required
      />

      <button className='formprev' onClick={prevStep}>Back</button>
      <button className='formnext' onClick={handleNext}>Next</button> {/* Updated button */}
    </div>
  );
};

export default Step5;
