import React, { useState } from 'react';

const Step2 = ({ nextStep, prevStep, handleChange, formData }) => {
  const [error, setError] = useState('');

  // Validation function to check if all fields are filled
  const validateForm = () => {
    if (
      !formData.name ||
      !formData.age ||
      !formData.dateOfBirth ||
      !formData.placeOfBirth ||
      !formData.citizenship ||
      !formData.address
    ) {
      setError('Please complete all fields before proceeding.');
      return false;
    }
    setError('');
    return true;
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: document.querySelector('.form-container').offsetTop,
      behavior: 'smooth',
    });
  };
  const handleNext = () => {
    if (validateForm()) {
      nextStep();
    }
    else{
      scrollToTop();
    }
  };

  return (
    <div style={{ paddingTop: '40px', paddingBottom: '40px' }}>
      <h2>Basic Information</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}

      <label>Name and Surname</label>
      <input
        type="text"
        value={formData.name}
        onChange={handleChange('name')}
        required
      />
      <label>Age</label>
      <input
        type="text"
        value={formData.age}
        onChange={handleChange('age')}
        required
      />
      <label>Date of Birth</label>
      <input
        type="date"
        value={formData.dateOfBirth}
        onChange={handleChange('dateOfBirth')}
        max="2006-09-11"  // Set the maximum date to September 11, 2006
        required
      />

      <label>Place of Birth</label>
      <input
        type="text"
        value={formData.placeOfBirth}
        onChange={handleChange('placeOfBirth')}
        required
      />
      <label>Citizenship(s)</label>
      <input
        type="text"
        value={formData.citizenship}
        onChange={handleChange('citizenship')}
        required
      />
      <label>Address (Residence)</label>
      <input
        type="text"
        value={formData.address}
        onChange={handleChange('address')}
        required
      />

      <button className='formprev' onClick={prevStep}>Back</button>
      <button className='formnext' onClick={handleNext}>Next</button> {/* Updated button */}
    </div>
  );
};

export default Step2;
