import React from 'react';
import './Sponsors.css';
const Sponsors = () => {
  return (
    <div className='sponsors'>
      <div className='container'>
      <h2>OUR SPONSORS</h2>
     
         

          <div className='row'>
            <div className='col-md-6 block-sponsors'>
              <a href='#' target='_blank'>
            <img src='/img/sponsori/ana maria makeup.jpg'></img>
            </a>
            <a href='#' target='_blank'>
            <img src='/img/sponsori/brandstage-logo.png'></img>
            </a>
            <a href='#' target='_blank'>
            <img src='/img/sponsori/Domeniul-Greaca-V2.png'></img>
            </a>
            <a href='#' target='_blank'>
            <img src='/img/sponsori/denttaglio-clinic.jpg'></img>
            </a>
            
            <a href='#' target='_blank'>
            <img src='/img/sponsori/hayat gazetesi.jpg'></img>
            </a>
              </div>
              <div className='col-md-6 block-sponsors'>
              <a href='#' target='_blank'>
              <img src='/img/sponsori/Kong GOLD.png'></img>
              </a>

              <a href='#' target='_blank'>
              <img src='/img/sponsori/logo_leclubfb.jpg'></img>
              </a>

              <a href='#' target='_blank'>
              <img src='/img/sponsori/Logo-H-Primary-350x100-1.png'></img>
              </a>

              <a href='#' target='_blank'>
              <img src='/img/sponsori/profa de modeling.jpg'></img>
              </a>

              <a href='#' target='_blank'>
              <img src='/img/sponsori/WDA.png'></img>
              </a>
              </div>
          </div>


        </div>
    </div>
  );
}

export default Sponsors;
